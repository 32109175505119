
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'About',
  props: {
    header: String,
    copy: String
  }
})
