
import { defineComponent } from 'vue'
import AboutSection from '../components/AboutSection.vue'

export default defineComponent({
  name: 'About',
  components: {
    AboutSection
  },
  data: function () {
    return {
      aboutSections: [
        {
          header: 'Professional Summary',
          copy: 'I work with the experts and the development resources to deliver product that adds service value and a greater experience for the users. I craft solutions in a variety of delivery solutions and industries with a sharp focus on solutions that take both a human visualization in the design process and functional deliverables and align within the strategic vision of the stakeholders.'
        }
      ],
      businessApplications: [
        'SAP', 'Microsoft Office Suite applications', 'Dynamics 365', 'Microsoft Project', 'Lectora', 'Siemens Rule stream', 'Maximo', 'Profuse', 'Tableau'
      ],
      techMgmtApplications: ['MS Team', 'MS Query Management Suite', 'SharePoint', 'Jira', 'Citrix', 'FileNet', 'JetBrains', 'Box', 'Trello', 'SQL', 'Visual Studio for sprint tracking', 'Miro'],
      servicesProvided: [
        'Business plan development, assessment and analysis',
        'Process mapping & improvement',
        'Expert in GAP analysis, SIPOC and other process analysis methodologies',
        'Facilitating software creation process and enhancement',
        'Coaching and mentoring across organizations along with training and team improvement.',
        'Requirement’s development and management at enterprise and business levels through workshops, site visits, building use case and stories, work process and task analysis.',
        'Strategic Planning',
        'New Business Development'
      ],
      softwareExpAreas: [
        'Game Development', 'Procurement & MRP', 'Software Migration', 'Enterprise Resource Planning', 'Customer Relationship Management', 'Logistics', 'Transportation', 'Event Management', 'Food & Beverage/Hospitality', 'Predictive & Preventive Maintenance', 'Business Transitions', 'Reporting'
      ]
    }
  },

  mounted: function () {
    // eslint-disable-next-line no-unused-expressions
    this.$store.state.perfectScrollbar?.update()

    // hack to remove uneven deadspace on small screen
    // console.log(window.innerWidth)
    if (window.innerWidth < 768) {
      this.techMgmtApplications.splice(this.techMgmtApplications.length - 2, 1)
    }
  }
})
